<template>
  <footer class="footer px-4 mx-2">
    <b-row align-v="center" class="justify-content-lg-between">
      <b-col lg="6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{year}} Xeoscript
        </div>
      </b-col>
    </b-row>
  </footer>
</template>
<script>
export default {
    data () {
        return {
            year: new Date().getFullYear()
        };
    }
};
</script>
<style></style>
