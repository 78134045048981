<template>
    <div class="wrapper">
        <notifications></notifications>
        <div class="main-content">
            <DashboardNavbar :type="$route.meta.navbarType || 'default'"></DashboardNavbar>
            <div @click="$sidebar.displaySidebar(false)">
                <fade-transition :duration="500" origin="center top" mode="out-in">
                    <!-- your content here -->
                    <router-view>

                    </router-view>
                </fade-transition>
            </div>
            <ContentFooter></ContentFooter>
        </div>
    </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import { FadeTransition } from 'vue2-transitions';

import 'perfect-scrollbar/css/perfect-scrollbar.css';
import ContentFooter from '@/layouts/components/ContentFooter';
import DashboardNavbar from '@/layouts/components/DashboardNavbar';
// import DashboardNavbar from './DashboardNavbar.vue';
// import ContentFooter from './ContentFooter.vue';
// import DashboardContent from './Content.vue';

function hasElement (className) {
    return document.getElementsByClassName(className).length > 0;
}

function initScrollbar (className) {
    if (hasElement(className)) {
        new PerfectScrollbar(`.${className}`);
    } else {
        // try to init it later in case this component is loaded async
        setTimeout(() => {
            initScrollbar(className);
        }, 100);
    }
}

export default {
    components: {
        DashboardNavbar,
        ContentFooter,
        // DashboardContent,
        FadeTransition
    },
    methods: {
        initScrollbar () {
            const isWindows = navigator.platform.startsWith('Win');
            if (isWindows) {
                initScrollbar('sidenav');
            }
        }
    },
    mounted () {
        this.initScrollbar();
    }
};
</script>
<style lang="scss"></style>
