<template>
    <base-nav container-classes="container-fluid" :show="true"
              class="navbar-top border-bottom navbar-expand bg-primary navbar-dark navbar-federal">

        <template slot="brand">
            <div class="navbar-wrapper">
                <router-link to="/" class="mr-0 mr-md-2">
<!--                    <img src="../../assets/img/logo.svg" class="logo" alt="Federal Bank"/>-->
<!--                    the logo from the back end should come here-->
                </router-link>
            </div>
        </template>

        <b-navbar-nav class="mr-auto">
            <b-nav-item to="/dashboard/">
                <span class="nav-link-inner--text text-sm">
                    <i class="ni ni-single-02"></i>
                    Dashboard
                </span>
            </b-nav-item>
            <b-nav-item to="/app/qr-code/" v-show="false">
                <a class="nav-link-inner--text text-sm">
                    <i class="ni ni-fat-add"></i>
                    QR Code
                </a>
            </b-nav-item>
            <b-nav-item to="/app/admin/category/">
                <span class="nav-link-inner--text text-sm">
                    <i class="ni ni-books"></i>
                    Category
                </span>
            </b-nav-item>
            <b-nav-item to="/app/admin/product/">
                <span class="nav-link-inner--text text-sm">
                    <i class="ni ni-cart"></i>
                    Product Master
                </span>
            </b-nav-item>
            <b-nav-item to="/app/admin/back-ground/image/">
                <span class="nav-link-inner--text text-sm">
                    <i class="ni ni-image"></i>
                    Background Image
                </span>
            </b-nav-item>
        </b-navbar-nav>

        <UserProfile></UserProfile>
    </base-nav>
</template>
<script>
// import { CollapseTransition } from 'vue2-transitions';
import { BaseNav } from 'argon/src/components';
import UserProfile from './navbar/UserProfile';
// import Apps from "./navbar/Apps";
// import Notifications from "./navbar/Notifications";

export default {
    components: {
        // CollapseTransition,
        BaseNav,
        // Apps,
        // Notifications,
        UserProfile
        // Modal
    },
    props: {
        type: {
            type: String,
            default: 'default', // default|light
            description: 'Look of the dashboard navbar. Default (Green) or light (gray)'
        }
    },
    computed: {
        routeName () {
            const { name } = this.$route;
            return this.capitalizeFirstLetter(name);
        }
    },
    data () {
        return {
            activeNotifications: false,
            showMenu: false,
            searchModalVisible: false,
            searchQuery: ''
        };
    },
    methods: {
        capitalizeFirstLetter (string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        toggleNotificationDropDown () {
            this.activeNotifications = !this.activeNotifications;
        },
        closeDropDown () {
            this.activeNotifications = false;
        },
        toggleSidebar () {
            this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
        },
        hideSidebar () {
            this.$sidebar.displaySidebar(false);
        }
    }
};
</script>
