<template>
    <b-navbar-nav class="align-items-center ml-auto ml-md-0">
        <base-dropdown menu-on-right class="nav-item" tag="li" title-tag="a" title-classes="nav-link pr-0">
            <a href="#" class="nav-link pr-0" @click.prevent slot="title-container">
                <b-media no-body class="align-items-center">
                    <b-media-body class="ml-2 d-none d-lg-block">
                        <span class="mb-0 text-sm  font-weight-bold" v-html="currentUser.name">John Snow</span>
                    </b-media-body>
                </b-media>
            </a>

            <template>
                <b-dropdown-item href="#!">
                    <i class="ni ni-calendar-grid-58"></i>
                    <span>Change Password</span>
                </b-dropdown-item>
                <b-dropdown-item href="#!">
                    <i class="ni ni-support-16"></i>
                    <span>Support</span>
                </b-dropdown-item>
                <div class="dropdown-divider"></div>
                <b-dropdown-item @click="logoutClicked">
                    <i class="ni ni-user-run"></i>
                    <span>Logout</span>
                </b-dropdown-item>

            </template>
        </base-dropdown>
    </b-navbar-nav>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import axios from 'axios';
import urls from '@/data/urls';

export default {
    computed: {
        ...mapGetters(['currentUser'])
    },
    methods: {
        ...mapActions(['signOut']),
        logoutClicked () {
            this.signOut();
            axios.get(urls.auth.logout);
            this.$router.push('/login/');
        }
    }
};
</script>
